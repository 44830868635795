<template>
  <v-form id="contact_form" novalidate="novalidate" class="col-12">
    <v-row>
      <v-col sm="5" cols="12">
        <img
          :src="
            require('../../../assets/images/silvereco/Innovations-New-Tech.png')
          "
        />
      </v-col>
      <v-col sm="7" cols="12">
        <H2>Votez pour Mes amis de confiance</H2>
        <v-text-field
          style="border:none;"
          hide-details="auto"
          prepend-icon="mdi-account"
          type="text"
          id="given_name"
          name="given_name"
          label="Prénom"
          :error-messages="givenNameErrors"
          v-model="personalInfo.given_name"
          @input="$v.personalInfo.given_name.$touch()"
          @blur="$v.personalInfo.given_name.$touch()"
        ></v-text-field>
        <v-text-field
          prepend-icon="mdi-account"
          type="text"
          id="name"
          name="name"
          label="Nom"
          :error-messages="nameErrors"
          v-model="personalInfo.name"
          @input="$v.personalInfo.name.$touch()"
          @blur="$v.personalInfo.name.$touch()"
        ></v-text-field>

        <v-text-field
          prepend-icon="mdi-cellphone-basic"
          type="text"
          id="custom:mobile"
          name="custom:mobile"
          label="Télèphone portable"
          placeholder="+33610234564"
          :error-messages="mobileErrors"
          v-model="personalInfo['custom:mobile']"
          @input="$v.personalInfo['custom:mobile'].$touch()"
          @blur="$v.personalInfo['custom:mobile'].$touch()"
        ></v-text-field>

        <v-text-field
          prepend-icon="mdi-email"
          type="text"
          id="email"
          name="email"
          label="Email"
          :error-messages="emailErrors"
          v-model="personalInfo.email"
          @input="$v.personalInfo.email.$touch()"
          @blur="$v.personalInfo.email.$touch()"
          placeholder="bob@myemail.fr"
        ></v-text-field>

        <v-checkbox
          v-model="cgu"
          :error-messages="CguErrors"
          @input="$v.cgu.$touch()"
          @blur="$v.cgu.$touch()"
        >
          <template v-slot:label>
            <div @click.stop="">
              J’accepte les
              <a href="/CGU" target="_blank"
                >Conditions Générale d’Utilisation</a
              >
              pour continuer.
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
          v-model="optin"
          :error-messages="OptinErrors"
          @input="$v.optin.$touch()"
          @blur="$v.optin.$touch()"
        >
          <template v-slot:label>
            <div @click.stop="">
              Je ne veux pas que mes données soient utilisées par des
              partenaires
            </div>
          </template>
        </v-checkbox>

        <div class="justify-center">
          <v-btn
            type="button"
            class="btn-custom"
            variant="outline-primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submit"
          >
            Voter
            <template v-slot:isLoading>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
          <span class="mx-5">
            <a
              target="_blank"
              href="https://www.silvereco.org/festival/fr/votez-pour-votre-candidat/"
              style="color:black; "
              >Site Officiel SilverEco.org</a
            >
          </span>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<style>
.v-text-field .v-label--active {
  transform: translateY(-24px) scale(0.75);
}

.v-text-field__slot,
.v-text-field__slot input {
  border: none;
}
</style>
<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, helpers, sameAs, email } from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ú \- s]+$/i);
const mobileNumber = helpers.regex(
  "mobileNumber",
  /^(\+33|0)(1|2|3|4|5|6|7|9)\d{8}$/i
);

export default {
  mixins: [validationMixin],
  validations: {
    cgu: { required, sameAs: sameAs(() => true) },
    optin: {},
    personalInfo: {
      email: { required, email: email(() => true) },
      name: { required, alpha },
      given_name: { required, alpha },
      "custom:mobile": { required, mobileNumber }
    }
  },
  components: {},
  async mounted() {},
  computed: {
    CguErrors() {
      const errors = [];
      if (!this.$v.cgu.$dirty) return errors;
      !this.$v.cgu.sameAs &&
        errors.push("Vous devez accepter les CGU pour voter.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.personalInfo.email.$dirty) return errors;
      !this.$v.personalInfo.email.email &&
        errors.push("l'email n'est pas valide. ");
      return errors;
    },
    OptinErrors() {
      const errors = [];

      return errors;
    },

    givenNameErrors() {
      const errors = [];

      if (!this.$v.personalInfo.given_name.$dirty) return errors;
      !this.$v.personalInfo.given_name.required &&
        errors.push("Le prénom est obligatoire");
      !this.$v.personalInfo.given_name.alpha &&
        errors.push("Le prénom ne doit contenir que des lettres");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.personalInfo.name.$dirty) return errors;
      !this.$v.personalInfo.name.required &&
        errors.push("Le nom est obligatoire");
      !this.$v.personalInfo.name.alpha &&
        errors.push("Le nom ne doit contenir que des lettres");

      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.personalInfo["custom:mobile"].$dirty) return errors;
      !this.$v.personalInfo["custom:mobile"].required &&
        errors.push("Le numéro de portable est obligatoire");
      !this.$v.personalInfo["custom:mobile"].mobileNumber &&
        errors.push("Le numéros doit être sous la forme +33623456789");
      return errors;
    }
  },
  data() {
    return {
      isLoading: false,
      cgu: false,
      optin: false,
      personalInfo: {
        email: "",
        name: "",
        given_name: "",
        phone_number: "",
        "custom:mobile": "",
        address: {}
      }
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (
        this.personalInfo["custom:mobile"] !== "" &&
        this.personalInfo["custom:mobile"].indexOf("+33") === -1
      ) {
        this.personalInfo["custom:mobile"] =
          "+33" + this.personalInfo["custom:mobile"].substring(1);
      }

      const form = new URLSearchParams();
      form.append("menu-531", "ShirkaLab");
      form.append("text-890", this.personalInfo.name); // nom
      form.append("text-146", this.personalInfo.given_name); // nom
      form.append("email-274", this.personalInfo.email); // nom
      form.append("checkbox-564", "Un particulier"); // nom
      form.append("tel-803", this.personalInfo["custom:mobile"]); // nom
      form.append("checkbox-2", "Non"); // nom
      form.append("checkbox-246[]", "J'accepte"); // nom
      if (this.optin) {
        form.append(
          "checkbox-680[]",
          "Je ne veux pas que mes données soient utilisées par des partenaires"
        ); // nom
      }

      fetch(
        "https://www.silvereco.org/festival/fr/wp-json/contact-form-7/v1/contact-forms/13543/feedback?_locale=user",
        {
          method: "POST",
          body: form
        }
      )
        .then(response => response.json())
        .then(function(data) {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
            heightAuto: false
          });
        })
        .catch(function(error) {
          Swal.fire({
            title: "",
            text: error.message,
            icon: "error",
            heightAuto: false
          });
        });
    }
  }
};
</script>
